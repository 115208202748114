import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import HypedView from '../views/HypedView.vue'
import NotFoundView from '../views/NotFoundView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    redirect: '/about',
    component: HomeView,
    meta: {
      title: 'Home | zsoltapagyi.com'
    }
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AboutView,
    meta: {
      title: 'About | zsoltapagyi.com'
    }
  },
  {
    path: '/setup',
    name: 'setup',
    component: () => import(/* webpackChunkName: "setup" */ '../views/SetupView.vue'),
    meta: {
      title: 'Setup | zsoltapagyi.com'
    }
  },
  {
    path: '/hyped',
    name: 'hyped',
    component: HypedView,
    meta: {
      title: 'Hyped | zsoltapagyi.com'
    }
  },
  {
    path: '/404',
    name: 'not-found',
    component: NotFoundView,
    meta: {
      title: '404 - Page Not Found | zsoltapagyi.com'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.afterEach((to, from) => {
  // Send page view event to Google Analytics
  if (window.gtag) {
    window.gtag('config', 'G-ZW5JCNVLR5', {
      page_path: to.fullPath,
      page_title: to.name
    })
  }
})

router.beforeEach((to, from, next) => {
  // Update the document title using the route's meta title
  document.title = to.meta.title || 'zsoltapagyi.com'
  next()
})

export default router
