const savedHypeViewMode = localStorage.getItem('hypeViewMode') || 'card'
const savedHypeTypeFilter = localStorage.getItem('hypeTypeFilter') || 'All'

export default {
  state: {
    hypeViewMode: savedHypeViewMode,
    hypeTypeFilter: savedHypeTypeFilter
  },
  mutations: {
    toggleHypeViewMode(state) {
      state.hypeViewMode = state.hypeViewMode === 'list' ? 'card' : 'list'
        localStorage.setItem('hypeViewMode', state.hypeViewMode)
    },
    setHypeTypeFilter(state, filter) {
      state.hypeTypeFilter = filter
      localStorage.setItem('hypeTypeFilter', filter)
    }
  },
  getters: {
    hypeViewMode: state => state.hypeViewMode,
    hypeTypeFilter: state => state.hypeTypeFilter
  }
} 