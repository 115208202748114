import VueGtag from 'vue-gtag'

export default {
  install(app, options) {
    app.use(VueGtag, {
      config: {
        id: 'G-ZW5JCNVLR5', // Replace with your Measurement ID
        enabled: process.env.NODE_ENV === 'production'
      }
    })
  }
} 